var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-data-table',{attrs:{"headers":[
      { text: 'IP', value: 'ip' },
      { text: 'verbunden seit', value: 'connecttime' },
      { text: 'aktuell', value: 'currentview' },
      { text: 'angemeldet als', value: 'person' }
    ],"items":_vm.ClientFind,"items-per-page":50},scopedSlots:_vm._u([{key:"item.person",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("person")(item.person)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }